<template>
    <div>
        <div class="holder">
            <div class="container-fluid">
                <div class="top-text">
                    <h4>My vouchers</h4>
                    <p>Track and manage all your vouchers in one place</p>
                </div>

                <div class="vouchers-buttons-types">
                    <button :class="tab == 1 ? 'activeTab' : ''" class="btn" @click="tab = 1; fetchAllVouchers()">All</button>
                    <button :class="tab == 2 ? 'activeTab' : ''" class="btn" @click="tab = 2; fetchAllVouchers()">Active</button>
                    <button :class="tab == 3 ? 'activeTab' : ''" class="btn" @click="tab = 3; fetchAllVouchers()">Used</button>
                </div>

                <div class="filter-holder">
                    <div class="filter-side row">
                        <div class="form-group col-md-8">
                            <input @input="formatInputRemoveCharacters" maxlength="19" :value="formattedInput" class="form-control input-field search" type="text" name="search" id="search" placeholder="Search voucher code...">
                            <!-- <input @input="formatInputRemoveCharacters" maxlength="19" :value="formattedInput" class="form-control input-text" type="text" name="vocher" id="voucher" placeholder="Enter voucher numbers"> -->
                        </div>
                        <div class="date-type-holder col-md-4">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <input @change="datechange()" type="text" onfocus="(this.type='date')" :max="today" v-model="createdDate" id="select-date" class="form-control input-field" placeholder="Select date">
                                    </div>
                                </div>
                                
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <select id="vouchertype" class="form-control input-field" data-toggle="modal" data-target="#filterModal"  >
                                            <option v-if="typeValue == ''" selected value="">Type</option>
                                            <option v-else>{{ typeValue.name }}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <h6 class="bulk-text-link">
                    <router-link to="bulk-vouchers-requests">
                        View bulk voucher requests
                    </router-link>
                </h6>
                <div class="table-responsive voucherListTable">
                    <div class="m-auto text-center mt-4" v-if="loading"><Loader/></div>
                    <table class="table" v-if="!loading && vouchers.length != 0">
                        <thead>
                            <tr>
                                <th scope="col">Voucher code</th>
                                <th scope="col">Category</th>
                                <th scope="col">Date purcahsed</th>
                                <th scope="col">Satus</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr  v-for="(item, index) in vouchers"
                                :key="index"
                                :value="item.id">
                                <td>{{ item.stringed }}</td>
                                <td>{{ item.name }} {{ item.lastname }}</td>
                                <td>{{ item.created_at | moment }}</td>
                                <td >
                                    <span :class="item.used == 'no' ? 'activeVoucher' : 'nonActiveVoucher'">{{ item.used == 'no' ? 'Active' : 'Used' }}</span>
                                </td>
                                <!-- <td>
                                    <img class="dropdown-toggle" style="cursor: pointer;" src="../assets/icons/menu-icon.svg" alt="menu" data-toggle="dropdown" aria-expanded="false">
                                    <div class="dropdown-menu">
                                    <a @click="viewLandlordDetails(item.id)" class="dropdown-item">View details</a>
                                    <a @click="addProperty(item.id)" class="dropdown-item">Add property (unit)</a>
                                    <a class="dropdown-item">Generate report</a>
                                    <a class="dropdown-item">Delete</a>
                                    </div>
                                </td> -->
                                </tr>
                        </tbody>
                    </table>
                    <div v-if="vouchers.length == 0 && !loading">
                    <EmptyState :emptyData="emptyDataObject" />
                    </div>
                </div>
            </div>


            <div
            tabindex="-1"
            class="modal fade"
            id="filterModal"
            role="dialog"
            aria-labelledby="sub"
            aria-hidden="true"
            >
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content" style="margin-bottom: 30px">
                    <div class="modal-header">
                        <h5 class="modal-title confirmation-header">
                        Filter
                        </h5>
                        <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        >
                        <span
                            @click="paymentType = ''"
                            aria-hidden="true"
                            style="font-weight: normal"
                            >X</span
                        >
                        </button>
                    </div>
                    <div class="modal-body">
                        
                        <div class="form-group">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <input type="text" onfocus="(this.type='date')" :max="today" v-model="startDate" id="from" class="form-control input-field select-date2" placeholder="From date">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <input type="text" onfocus="(this.type='date')" :max="today" v-model="endDate" id="to" class="form-control input-field select-date2" placeholder="To date">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group">
                            <label for="typee">Type/Category</label>
                            <select v-model="voucherTypeId" id="vouchertype" class="form-control input-field" @change="getTypeValue(voucherTypeId)">
                                <option selected value="">Type</option>
                                <option v-for="(item, index) in voucherTypes" :key="index" :value="item.id">{{ item.name }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="modal-footer modal-button">
                        <button
                        @click="resetFilter()"
                        type="button"
                        class="btn cancel voucherCancel"
                        data-dismiss="modal"
                        >
                        Cancel/Reset
                        </button>
                        <button
                        :disabled="loading"
                        type="button"
                        class="btn continue voucherPay"
                        @click="applyFilter()"
                        >
                        Apply
                        <i
                            class="fa fa-spinner fa-spin ml-3 f-12 btn-loader"
                            v-if="loading"
                        ></i>
                        </button>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
// import transactionPin from "../services/transactionPin";

import generatingVoucher from '../services/voucher';
import EmptyState from "../components/EmptyState.vue";
import Loader from '../components/Loader';
import moment from 'moment';
import $ from "jquery";

export default {
   name: "VoucherLists",
   components: {
        Loader,
        EmptyState
   },
    data() {
      return {
        loading: false,
        tab: 1,
        vouchers: [],
        voucherTypes: [],
        typeValue: "",
        voucherTypeId: "",
        createdDate: "",
        startDate: "",
        endDate: "",
        typeName: "",
        codeSearch: "",
        emptyDataObject: {
          header: 'No record found',
          option: 'Go to dashboard, click on the generate voucher button to generate voucher'
        },
        today: new Date().toISOString().split("T")[0], // Gets today's date in YYYY-MM-DD format
      }
    },
    computed: {
        formattedInput() {
          return this.formatWithHyphens(this.codeSearch);
        },
    },
    watch: {
        codeSearch(newValue) {
         if (newValue.length === 16) {
            return this.fetchAllVouchersWithFilter();
         }
         if(newValue == '') {
            this.fetchAllVouchers();
         }
     },
     deep: true,
    },
    mounted() {
        this.fetchAllVouchers();
        this.fetchVoucherTypes();
    },
    methods: {
        resetFilter() {
            this.startDate = "";
            this.endDate = "";
            this.voucherTypeId = "";
            this.typeValue = "";

        },
        formatInputRemoveCharacters(event) {
            let value = event.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric values
            value = value.slice(0, 16); // Limit to 16 digits (without hyphens)
            this.codeSearch = value; // Update the raw input
        },
        formatWithHyphens(value) {
            return value.replace(/(\d{4})(?=\d)/g, "$1-"); // Add hyphen after every 4 digits except last
        },
        getTypeValue(id) {
            return this.typeValue = this.voucherTypes.find(item => item.id === id);
        }, 
        datechange() {
            this.tab = 1;
            this.fetchAllVouchersWithFilter();
        },
        applyFilter() {
            if (this.startDate == '' || this.endDate == '' || this.voucherTypeId == '') {
                return this.$toast.error("Please selectt all fields");
            }
            this.tab = 1;
            this.fetchAllVouchersWithFilter()
        },
        fetchAllVouchersWithFilter() {
            this.loading = true;
            let url = null;
            if (this.tab == 1) {
                url = `fetch-user-all-vouchers?used=${'no'}&code=${this.codeSearch}&created_date=${this.createdDate}&voucher_type_id=${this.voucherTypeId}&from=${this.startDate}&to=${this.endDate}`
            }
            generatingVoucher.allVouchers(url).then(res => {
                // console.log("Vlouchers", res);
                this.loading = false;
                // this.resetFilter();
               if (res.success) {
                $("#filterModal")
                .removeClass("fade")
                .modal("hide");
                this.vouchers = res.vouchers.data;
               }

            }).catch((err) => {
                this.loading = false;
                console.log(err);
            });
        },
        fetchAllVouchers() {
            this.resetFilter();
            this.loading = true;
            let url = null;
            if (this.tab == 1) {
                url = `fetch-user-all-vouchers?created_dat=${this.createdDate}`
            }
            if (this.tab == 2) {
                url = 'fetch-user-active-vouchers'
            }
            if (this.tab == 3) {
                url = 'fetch-user-used-vouchers'
            }
            generatingVoucher.allVouchers(url).then(res => {
                // console.log("Vlouchers", res);
                this.loading = false;
               if (res.success) {
                this.vouchers = res.vouchers.data;
               }
            }).catch((err) => {
                this.loading = false;
                console.log(err);
            });
        },
        fetchVoucherTypes() {
            generatingVoucher.voucherTypes().then((res) => {
                this.voucherTypes = res.voucher_types;
                // console.log("Voucher Types", this.voucherTypes)
            }).catch((err) => {
                console.log(err);
            });
        },
   },
   filters: {
        moment: function(date) {
        return moment(date).format('MMMM Do YYYY');
        }
        // moment: function(date) {
        //   return moment(date).format('MMMM DD YYYY, h:mm:ss a');
        // }
    },
    immediate: true
}
</script>


<style scoped lang="scss">

$primary: #0033ea;
$white: #ffffff;
$yellow: #ffb100;
$asheGrey: #555C74;
$offblack: #2B3352;

$shelta-black: #000a2f;
$shelta-gray: #555c74;

$secondary: #ffffff;
$black: #000000;
$dline: #dddddd;

.holder {
    background: #ffffff;
    padding: 30px 0px;
}

.top-text {
    h4 {
        font-family: Lato;
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        color: #2B3352;
    }
    p {
        font-family: Lato;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #555C74;

    }
}

.vouchers-buttons-types {
    background: #FAFAFA;
    width: fit-content;
    margin-top: 20px;
    button {
        background: #FAFAFA;
        color: #555C74;
        font-family: Lato;
        font-weight: 600;
        font-size: 14px;
        width: 147px;
        min-height: 36px;
        border: 1px solid #E9EAEB;
        border-radius: 0;
        outline: 0;
        box-shadow: none;
    }
}

.activeTab {
    background: #FFFFFF !important;
    border: 1px solid #D5D7DA !important;
    border-radius: 5px !important;
}


.voucherListTable {
    // margin-top: 30px;
    margin-bottom: 20px;
    border: 2px solid #EAECF0;
    border-radius: 20px;
}


.table thead th {
    border-bottom: none;
    
}
.table td {
    border-top: 1px solid #EAECF0;
}
.table th {
    border-top: none;
}

thead > tr {
    background: #EAECF0;
}
thead th {
    white-space: nowrap;
    font-family: Lato;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
    color: #667085
}

tbody td {
    padding: 18px .75rem;
    white-space: nowrap;
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #667085
}

.activeVoucher {
    font-family: Lato;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #067647;
    background: #ECFDF3;
    border-radius: 50px;
    padding: 4px 8px;
    // text-align: center;

}
.nonActiveVoucher {
    font-family: Lato;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    // text-align: center;
    color: #555C74;
    background: #FAFAFA;
    border-radius: 50px;
    padding: 4px 8px;
}

.filter-holder {
    margin-top: 30px;
}

.input-field {
    height: 48px;
    outline: 0;
    box-shadow: none;
}

.search {
   width: 70%; 
}

// .filter-side {
//     // display: flex;
//     // justify-content: space-between;
// }
// .date-type-holder {
//     // display: flex;
//     // justify-content: space-between;
// }

.voucherCancel, .voucherPay {
  width: 45% !important;
}
.voucherCancel, .voucherPay {
  width: 45% !important;
}
.modal-button {
  // padding-top: 24px;
  // padding-bottom: 40px;
  padding-top: 15px;
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;
  border-top: none !important;

  .cancel {
    border-radius: 6px;
    border: 1px solid $dline;
    color: $shelta-black;
    width: 178px;
    height: 48px;
    text-align: center;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    outline: 0;
    box-shadow: none;
  }

  .continue {
    border-radius: 6px;
    background: $primary;
    color: $secondary;
    // width: 178px;
    min-height: 48px;
    text-align: center;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    outline: 0;
    box-shadow: none;
  }
}

.bulk-text-link {
    // color: #555C74;
    color: #667085;
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    cursor: pointer;
    text-align: left;
    margin-bottom: 20px;
    margin-top: 30px;
    a {
        color: inherit;
        text-decoration: underline;
        outline: 0;
        border: 0;
        border: 1px solid #ced4da;
        padding: 10px;
        border-radius: 4px;
    }
}
@media screen and (max-width: 599px) {
    .vouchers-buttons-types  {
        button {
            width: 86px;
            // width: 98px;
        }
    }
    .search {
        width: 100%; 
    }
    #select-date {
        margin-bottom: 10px;
    }

    .select-date2 {
        margin-bottom: 10px;
    }
}


</style>